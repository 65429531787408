import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/post-list"
import PageSelector from "../components/page-selector"

const PostsByPlaceTrip = ({ data, location, pageContext }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const posts = data.allMdx.edges
  const currentPage = pageContext.currentPage
  const numPages = pageContext.numPages
  const slug = pageContext.slug
  const pageTitle =
    pageContext.name + (currentPage === 1 ? "" : ": Página " + currentPage)

  return (
    <Layout description={description} logo={logo}>
      <Seo title={pageTitle} />
      <h1 itemProp="headline">{pageTitle}</h1>
      <PostList posts={posts} />
      <PageSelector
        currentPage={currentPage}
        numPages={numPages}
        urlPrefix={`/viagens/${slug}/`}
      />
    </Layout>
  )
}

export default PostsByPlaceTrip

export const postsByPlaceTrip = graphql`
  query postsByPlaceTrip($name: String, $skip: Int!, $limit: Int!) {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          layout: { ne: "page" }
          tags: { in: [$name] }
          categories: { in: "Viagens" }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            featuredImageCaption
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 900, layout: CONSTRAINED)
              }
            }
            featuredImageAlt
          }
        }
      }
    }
  }
`
